import { IAgreementId } from './IAgreement';
import { Dayjs } from 'dayjs';


export enum stepsValue {
    BASIC_INFORMATION = 'basicInformation',
    PERSONAL_INFORMATION = 'personalInformation',
    ADDRESS_INFORMATION = 'addressInformation',
    FINANCE_INFORMATION = 'financeInformation',
    EMPLOYEE_INFORMATION = 'employeeInformation',
}

export enum loanReasonValue {
    VACATIONS = '7',
    CAR = '6',
    RENOVATION = '9',
    PAYMENTS = '2',
    EDUCATION = '11',
    OTHER = '1',
}

export enum maritalStatusValues {
    SINGLE = "2",
    MARRIED = "3",
    WIDOW = "5",
    SEPARATION = "6",
    DIVORCED = "7",
    OTHER = "1",
}

export enum hasDebtValues {
    NO = "0",
    YES = "1",
}

export enum homePropertyOwnershipValues {
    OWNED = "2",
    RENTED = "3",
    FAMILY = "4",
    MUNICIPAL = "5",
    COOPERATIVE = "6",
    OTHER = "1",
}

export enum homePropertyTypeValues {
    HOUSE = "2",
    APARTMENT = "3",
    SEGMENT = "4",
    SEMI_DETACHED = "5",
    OTHER = "1",
}

export enum educationValues {
    PRIMARY = "2",
    GYMNASIUM = "3",
    PROFESSIONAL = '4',
    SECONDARY = "5",
    SECONDARY_TECH = "6",
    BACHELORS = "7",
    MSC = "8",
    // MBA = "9", not included in form
    PHD = "10",
    OTHER = "1",
}

export enum incomeSourceValues {
    AGRICULTURE = "2",
    SELF_EMPLOYED = "3",
    FIXED = "4",
    FIXED_PARTIAL = "5",
    PRODUCT_CONTRACT = "6",
    SERVICE_CONTRACT = "7",
    RETIRE = "8",
    PENSION_PARTIAL = "10",
    PENSION = "9",
    STUDENT = "12",
    ALLOWANCE = "13",
    UNEMPLOYED = "11",
    OTHER = "1",
}

export enum carStatusValues {
    YES = "1",
    NO = "0",
}

export const noIncomeEmploymentOptions = [
    incomeSourceValues.ALLOWANCE,
    incomeSourceValues.UNEMPLOYED,
    incomeSourceValues.RETIRE,
    incomeSourceValues.PENSION,
    incomeSourceValues.PENSION_PARTIAL,
    incomeSourceValues.STUDENT,
]

export interface BeforeAgreementsScope {
    amount: number;
    period: string;
    email: string;
    phone_country_prefix: string;
    phone_mobile: string;
    pesel: string;
    loan_reason: loanReasonValue;
}

export interface BasicInformationStep {
    beforeAgreements: BeforeAgreementsScope;
    agreements: IAgreementId[];
}

export interface PersonalInformationStep {
    first_name: string;
    last_name: string;
    // gender: string;
    // date_of_birth: Dayjs|null;
    id_code: string;
    id_card_number_issued: Dayjs|null;
    marital_status: maritalStatusValues|'',
    amount_of_children: string,
    has_debt: hasDebtValues|'',
}

export interface AddressInformationStep {
    house_number: string;
    apartment_number: string;
    street: string;
    text_city: string;
    text_zip: string;
    home_property_ownership: homePropertyOwnershipValues|'';
    home_property_type: homePropertyTypeValues|'';
    home_property_residency_start_date: Dayjs|null;
}

export interface FinanceInformationStep {
    education: educationValues|'';
    income_source: incomeSourceValues|'';
    income: null|string;
    home_income_value: null|string;
    home_monthly_expenses: null|string;
    car_status: carStatusValues|'';
    license_plate_number?: string;
    bank_account_country: string;
    bank_account_number: string;
}

export interface EmployeeInformationStep {
    employee_nip: string,
    employee_zip: string,
    employee_city: string,
    employee_street: string,
    industry: string,
    occupation: string,
    work_seniority: Dayjs|null,
    work_company_name?: string,
}

export interface LoanFormValues {
    basicInformation: BasicInformationStep,
    personalInformation: PersonalInformationStep,
    addressInformation: AddressInformationStep,
    financeInformation: FinanceInformationStep,
    employeeInformation?: EmployeeInformationStep
}

export const defaultPhoneCountryPrefix = '+48';
export const defaultBankAccountCountry = 'PL';

const searchParams = new URLSearchParams(window.location.search);

const amount = searchParams.get('amount');


export const basicInformation: BasicInformationStep = {
    beforeAgreements: {
        amount: Number(amount) || 1000,
        period: '1',
        email: '', //bounce email
        phone_country_prefix: defaultPhoneCountryPrefix, //divide numbers add +34
        phone_mobile: '', //divide numbers add +34
        pesel: '',
        loan_reason: loanReasonValue.VACATIONS,
    },
    agreements: []
}

export const personalInformation: PersonalInformationStep = {
    first_name: '',
    last_name: '',
    // gender: '',
    // date_of_birth: null,
    id_code: '', //custom validation
    id_card_number_issued: null,
    marital_status: '',
    amount_of_children: '',
    has_debt: '',
}

export const addressInformation: AddressInformationStep = {
    text_city: '',
    text_zip: '',
    street: '',
    house_number: '',
    apartment_number: '',
    home_property_ownership: '',
    home_property_type: '',
    home_property_residency_start_date: null,
}

export const financeInformation: FinanceInformationStep = {
    education: '',
    income_source: '',
    income: '',
    home_income_value: '',
    home_monthly_expenses: '',
    car_status: '',
    license_plate_number: '',
    bank_account_country: defaultBankAccountCountry,
    bank_account_number: '',
}

export const employeeInformation: EmployeeInformationStep = {
    employee_city: '',
    employee_nip: '',
    employee_street: '',
    employee_zip: '',
    industry: '',
    occupation: '',
    work_seniority: null,
    work_company_name: '',
}

const INITIAL_FORM_STATE: LoanFormValues = {
    basicInformation,
    personalInformation,
    addressInformation,
    financeInformation,
    employeeInformation,
};

export default INITIAL_FORM_STATE;
