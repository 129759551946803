import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/poiret-one';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FinsalesProvider } from "./FinsalesContext";
import { GlobalStyles } from '@mui/material';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

import semiCircleRight from './assets/images/semiCircleRight.svg';
import semiCircleLeft from './assets/images/semiCircleLeft.svg';

const rootElement = document.getElementById('root');
if (rootElement) {
    const logoText = rootElement.getAttribute('data-logo-text') || 'funeda';
    const logoSize = rootElement.getAttribute('data-logo-size') || '4rem;';
    const baseColorH = rootElement.getAttribute('data-base-color-h') || '194'; // Domyślny kolor
    const baseColorS = rootElement.getAttribute('data-base-color-s') || '51%'; // Domyślny kolor
    const baseColorL = rootElement.getAttribute('data-base-color-l') || '28%'; // Domyślny kolor
    const secondColorH = rootElement.getAttribute('data-second-color-h') || baseColorH; // Drugi kolor
    const secondColorS = rootElement.getAttribute('data-second-color-s') || baseColorS; // Drugi kolor
    const secondColorL = rootElement.getAttribute('data-second-color-l') || baseColorL; // Drugi kolor
    const maxAmount = parseInt(rootElement.getAttribute('data-max-amount') || '100000') || 100000;
    const maxPeriod = parseInt(rootElement.getAttribute('data-max-period') || '120') || 120;
    const flowType = rootElement.getAttribute('data-flow-type') || 'redirect';
    const baseUrl = rootElement.getAttribute('data-base-url') || 'https://finsales-pl.finelf.com/api/v1';
    const agreementsUri = rootElement.getAttribute('data-agreement-uri') || 'agreements';
    const dataUri = rootElement.getAttribute('data-data-uri') || 'send_lead/b644f17b-0e76-4863-84e6-24d122314c4b';
    const fallbackUrl = rootElement.getAttribute('data-fallback-url') || 'https://funeda.pl/wall-of-offers';
    const intervalSeconds = parseInt(rootElement.getAttribute('data-interval') || '180') || 180;
    const attempts = parseInt(rootElement.getAttribute('data-attempts') || '1') || 1;
    const originToken = rootElement.getAttribute('data-origin-token') || null;
    const authNeeded= !!(rootElement.getAttribute('data-auth-needed')) || false;
    const root = ReactDOM.createRoot(rootElement!);


    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <GlobalStyles
                    styles={{
                        ':root': {
                            '--base-color-h': baseColorH, // Ustalony odcień (hue)
                            '--base-color-s': baseColorS, // Nasycenie (saturation)
                            '--base-color-l': baseColorL, // Jasność (lightness)
                            '--second-color-h': secondColorH, // Ustalony odcień (hue)
                            '--second-color-s': secondColorS, // Nasycenie (saturation)
                            '--second-color-l': secondColorL, // Jasność (lightness)
                            '--grey-221': 'rgba(221, 221, 221, 0.60)',
                            '--DFDFDF': '#DFDFDF',
                            '--input-color': '#DDD',
                            '--input-bg-color': 'rgba(0, 0, 0, 0.40)',
                            '--modal-bg-color': 'rgba(0, 0, 0, 0.90)',
                            '--logo-size': logoSize,
                        },
                        html: {
                            height: '100%',
                            fontSize: '10px'
                        },
                        body: {
                            height: '100%',
                            backgroundColor: 'hsl(var(--second-color-h), var(--second-color-s), var(--second-color-l))',
                            backgroundImage: `url(${semiCircleRight}), ` +
                                `url(${semiCircleLeft}), ` +
                                'linear-gradient(180deg, ' +
                                'hsl(var(--base-color-h), calc(var(--base-color-s) + 16%), calc(var(--base-color-l) - 11%)) 0%, ' +
                                'hsla(var(--base-color-h), calc(var(--base-color-s) + 42%), calc(var(--base-color-l) - 5%), 0.94) 19.10%, ' +
                                'hsla(var(--base-color-h), calc(var(--base-color-s) + 34%), calc(var(--base-color-l) + 2%), 0.90) 34.72%, ' +
                                'hsla(var(--base-color-h), calc(var(--base-color-s) + 6%), calc(var(--base-color-l) + 11%), 0.91) 45.66%, ' +
                                'hsla(var(--base-color-h), calc(var(--base-color-s) + 10%), calc(var(--base-color-l) + 13%), 0.92) 59.72%, ' +
                                'hsl(var(--second-color-h), var(--second-color-s), var(--second-color-l)) 100%)',
                            backgroundSize: '125px 250px, 125px 250px, 100% 800px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 70px, left 320px, top',
                        },
                    }}
                />
                <FinsalesProvider value={{logoText, maxAmount, maxPeriod, flowType, baseUrl, agreementsUri, dataUri, fallbackUrl, intervalSeconds, attempts, originToken, authNeeded}}>
                    <App/>

                </FinsalesProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
}

reportWebVitals();
