import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import 'dayjs/locale/pl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { plPL } from '@mui/x-date-pickers/locales';

import { useAgreements } from './hooks/useAgreements';
import { Loader } from './components';
import ThankYouPage from "./components/ThankYouPage";

const LoanForm = lazy(() => import('./layout/LoanForm'));
const UpdateLoanForm = lazy(() => import('./layout/UpdateLoanForm'));

export default function App() {
    const { agreements, requiredAgreementsIds } = useAgreements();

    const AgreementsLoaded = agreements.length > 0;

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='pl'
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <Router>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/" element={
                            AgreementsLoaded ? (
                                <LoanForm agreements={agreements} requiredAgreementsIds={requiredAgreementsIds} />
                            ) : (
                                <Loader />
                            )
                        } />
                        <Route path="/:origin_token" element={
                            AgreementsLoaded ? (
                                <UpdateLoanForm agreements={agreements} requiredAgreementsIds={requiredAgreementsIds} />
                            ) : (
                                <Loader />
                            )
                        } />
                        <Route path="/thank-you/:origin_token" element={<ThankYouPage />} />
                    </Routes>
                </Suspense>
            </Router>
        </LocalizationProvider>
    );
}
